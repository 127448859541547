<template>
    <div></div>
</template>
<script>
export default {
    created(){
    },
    mounted(){
        let url=window.location.href;
        let data = url.split('shareParamData=')[1];
        window.location.href=decodeURIComponent(data) 
    }
}
</script>
